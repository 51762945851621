import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as GatsbyTypes from '../../graphqlTypes';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import PageTitle from '../../components/PageTitle';
import Banner from '../../components/Banner';
import Grid from '../../components/Grid';
import CenteredBox, { CenteredBoxSizes } from '../../components/CenteredBox';
import { scale } from '../../styled';
import ButtonLink from '../../components/ButtonLink';
import ServicesBlock, { ServiceBlockTypes } from '../../components/ServicesBlock';
import RequestQuoteBlock from '../../components/RequestQuoteBlock';
import ReassuranceBlock from '../../components/ReassuranceBlock';

const UrgentTransportPage: React.FC = () => {
  const { file } = useStaticQuery<GatsbyTypes.GetUrgentTransportPageQuery>(
    graphql`
      query GetUrgentTransportPage {
        file(name: { eq: "service-urgent-transport" }) {
          id
          childMarkdownRemark {
            id
            html
            frontmatter {
              title
              banner {
                childImageSharp {
                  gatsbyImageData(height: 440, layout: FULL_WIDTH)
                }
              }
              image_1 {
                childImageSharp {
                  gatsbyImageData(width: 864, layout: CONSTRAINED)
                }
              }
              image_2 {
                childImageSharp {
                  gatsbyImageData(width: 500, layout: CONSTRAINED)
                }
              }
              seo {
                title
                description
                image {
                  publicURL
                }
              }
            }
          }
        }
      }
    `
  );

  const seoData = file?.childMarkdownRemark?.frontmatter?.seo;
  /* istanbul ignore next */
  if (!seoData?.title || !seoData.description) {
    throw new Error('[Missing data] Page SEO meta');
  }

  const pageData = file?.childMarkdownRemark?.frontmatter;
  /* istanbul ignore next */
  if (!pageData?.title) {
    throw new Error('[Missing data] Page content');
  }

  const bannerImg = pageData.banner?.childImageSharp?.gatsbyImageData;

  return (
    <Layout hasBlocks={false}>
      <SEO
        title={seoData.title}
        description={seoData.description}
        image={seoData.image?.publicURL}
      />
      <PageTitle>{pageData.title}</PageTitle>
      {bannerImg && <Banner img={bannerImg} />}
      <CenteredBox size={CenteredBoxSizes.S}>
        <p style={{ marginBottom: scale(2) }}>
          Un besoin urgent ? Nous nous plions en trois pour livrer aux quatre coins de Nantes. Le
          vélo est le moyen de transport <b>le plus rapide en centre ville</b> : nous proposons donc
          la course urgente, effectuée en moins de 45 minutes.
        </p>
        <h2>Un service rapide et de qualité</h2>
        <p>
          Vous avez des plis ou marchandises à expédier urgemment dans Nantes et son agglomération ?
        </p>
        <p>
          Un simple appel et votre coursier Green Course procède à l’enlèvement de votre colis en
          vue de son acheminement, puis à sa remise en main propre. Vos impératifs sont respectés
          grâce à notre <b>service de course urgente !</b>
        </p>
        <p style={{ marginBottom: scale(2) }}>
          La réactivité est au coeur de notre métier : le transport de vos marchandises est entre de
          bonnes mains grâce à nos coursiers expérimentés.
        </p>
        <Grid>
          <React.Fragment>
            <GatsbyImage
              image={pageData.image_1?.childImageSharp?.gatsbyImageData}
              alt=""
              style={{ maxWidth: '100%', borderRadius: scale(2) }}
            />
          </React.Fragment>
          <React.Fragment>
            <h2>Votre livraison express à Nantes</h2>
            <p>
              Pour vos colis express ou le transport urgent de vos courriers, optez pour un coursier
              à vélo ! Nos livreurs ne subissent pas les tracas du trafic routier, ce qui leur
              permet de répondre à vos délais de livraison serrés.
            </p>
          </React.Fragment>
        </Grid>
        <h2>Le vélo, un outil de logistique urbaine réactif</h2>
        <p>
          Notre service de livraison rapide transporte vos envois à destination de Nantes, en
          centre-ville ou en périphérie. Nous traversons l’agglomération nantaise en 45 minutes !
        </p>
        <p style={{ textAlign: 'center', marginBottom: scale(2) }}>
          <ButtonLink to="/contact">Une urgence ? Appelez nous</ButtonLink>
        </p>
        <GatsbyImage
          image={pageData.image_2?.childImageSharp?.gatsbyImageData}
          alt=""
          style={{ width: '100%', borderRadius: scale(2) }}
        />
      </CenteredBox>
      <ServicesBlock
        title="Nos autres services"
        data-testid="block-services"
        displayedBlockTypes={[ServiceBlockTypes.MailService, ServiceBlockTypes.PackageDelivery]}
      />
      <RequestQuoteBlock data-testid="block-request-quote" />
      <ReassuranceBlock id="block-reassurance" data-testid="block-reassurance" />
    </Layout>
  );
};

export default UrgentTransportPage;
